import { equals } from 'ramda';
import type {
  AdClickEvent,
  AdImpressionEvent,
  CommonEventProperties,
  LinkClickEvent,
  StructuredEvent,
} from '@snowplow/tracker-core';
import type { Cart, ListViewEvent, ListClickEvent, Product, CommonEcommerceEventProperties, SPPromotion } from '@snowplow/browser-plugin-snowplow-ecommerce';

export default function useTracking() {
  /********************
   * REFS & VARS       *
   ********************/
  const impressionId = ref(`${new Date().getTime()}${Math.random()}`);

  /********************
   * COMPOSITIONS      *
   ********************/
  const localeRoute = useLocaleRoute();
  const route = useRoute();
  const router = useRouter();
  const runtimeConfig = useRuntimeConfig();
  const {
    $setCustomUrl,
    $trackAdClick,
    $trackAdImpression,
    $trackAddToCart,
    $trackLinkClick,
    $trackProductListClick,
    $trackProductListView,
    $trackProductView,
    $trackPromotionClick,
    $trackPromotionView,
    $trackStructEvent,
  } = useNuxtApp();

  /********************
   * FUNCTIONS         *
   ********************/
  function trackAdClickAndFollow(event: Event, spEvent: any) {
    const href = event?.currentTarget?.getAttribute('href');
    const target = event?.currentTarget?.getAttribute('target');
    const selfTarget = !target || target === '_self';
    const isAbsolute = isAbsoluteUrl(href);
    const isInternal = isInternalUrl(href, runtimeConfig.public.baseUrl);
    // const isAnchor = isAnchorUrl(href);
    const preventEvent = selfTarget && isInternal; // && !isAnchor;

    // if target is same window and link is not external, prevent
    if (preventEvent) {
      event.preventDefault();
    }

    if (!spEvent.targetUrl) {
      if (isAbsolute) {
        spEvent.targetUrl = href;
      } else {
        const envURL = runtimeConfig.public.baseUrl.replace(/\/+$/, '');
        spEvent.targetUrl = `${envURL}${href}`;
      }
    }

    const { advertiserId, bannerId, campaignId, clickId, context, cost, costModel, impressionId, targetUrl, zoneId }
      = spEvent;
    trackAdClick({
      advertiserId,
      bannerId,
      campaignId,
      clickId,
      context,
      cost,
      costModel,
      impressionId,
      targetUrl,
      zoneId,
    });

    if (preventEvent) {
      let to = href;
      if (isAbsolute) {
        const url = new URL(href);
        to = url.pathname + (url.search || '');
      }
      const matchedRoute = router.resolve(to);
      if (matchedRoute.matched.length) {
        if (matchedRoute.hash) {
          const routeWithoutHash1 = localeRoute(matchedRoute.fullPath.replace(/#[a-z0-9_-]*/i, ''));
          const routeWithoutHash2 = localeRoute(route.fullPath.replace(/#[a-z0-9_-]*/i, ''));
          if (equals(routeWithoutHash1, routeWithoutHash2)) {
            const ele = window?.document?.getElementById(matchedRoute.hash.substring(1));
            if (ele) {
              ele.scrollIntoView({ block: 'start' });
            }
          }
        }
        return navigateTo(matchedRoute.fullPath);
      }
      const resolved = router.resolve(route); //
      const currentHref = resolved.href || null;
      if (href !== currentHref) {
        return navigateTo(href);
      }
    }
  }

  function trackAdImpression(event: AdImpressionEvent & CommonEventProperties, _trackers?: Array<string>) {
    if ($trackAdImpression) {
      $trackAdImpression(event);
    }
  }

  function trackAdClick(event: AdClickEvent & CommonEventProperties, _trackers?: Array<string>) {
    if ($trackAdClick) {
      $trackAdClick(event);
    }
  }

  function trackLinkClick(event: LinkClickEvent & CommonEventProperties, _trackers?: Array<string>) {
    if ($trackLinkClick) {
      $trackLinkClick(event);
    }
  }

  function trackStructEvent(event: StructuredEvent & CommonEventProperties, _trackers?: Array<string>) {
    if ($trackStructEvent) {
      $trackStructEvent(event);
    }
  }

  function trackProductView(productView: Product & CommonEcommerceEventProperties) {
    if ($trackProductView) {
      $trackProductView(productView);
    }
  }

  function trackAddToCart(cart: Cart & CommonEcommerceEventProperties) {
    if ($trackAddToCart) {
      $trackAddToCart(cart);
    }
  }

  function trackProductListView(listView: ListViewEvent & CommonEcommerceEventProperties) {
    if ($trackProductListView) {
      $trackProductListView(listView);
    }
  }

  function trackProductListClick(listClick: ListClickEvent & CommonEcommerceEventProperties) {
    if ($trackProductListClick) {
      $trackProductListClick(listClick);
    }
  }

  function trackPromotionView(promotionView: SPPromotion & CommonEcommerceEventProperties) {
    if ($trackPromotionView) {
      $trackPromotionView(promotionView);
    }
  }

  function trackPromotionClick(promotionClick: SPPromotion & CommonEcommerceEventProperties) {
    if ($trackPromotionClick) {
      $trackPromotionClick(promotionClick);
    }
  }

  function setCustomUrl(url: string, trackers?: Array<string>) {
    $setCustomUrl(url, trackers);
  }

  return {
    impressionId,
    setCustomUrl,
    trackAdClick,
    trackAdClickAndFollow,
    trackAdImpression,
    trackAddToCart,
    trackLinkClick,
    trackProductListClick,
    trackProductListView,
    trackProductView,
    trackPromotionClick,
    trackPromotionView,
    trackStructEvent,
  };
}
